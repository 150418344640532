import {
  Accordion,
  AccordionDetails,
  AccordionSummaryFullWidthRow,
  SideWrapper,
} from '@frontend/ui-elements';
import { LISTING_ORDER_STATUS } from '@frontend/api';
import { useMediaQuery, useTheme } from '@mui/material';
import {
  AccordionSummary,
  OrderMobileWrapper,
  OrderWrapper,
} from './ListingOrderCard.css';
import { IListingOrderCard } from './ListingOrderCard.types';
import ListingOrderOverview from './ListingOrderOverview/ListingOrderOverview';
import ListingOrderCardDetails from './ListingOrderCardDetails/ListingOrderCardDetails';
import { LISTING_ORDER_CARD_STAGES } from '@customer-platform-shared/components/OrderStatusBar/OrderStatusBar.const';
import OrderStatusBar from '@customer-platform-shared/components/OrderStatusBar/OrderStatusBar';
import { useListingOrderCardScroll } from './useListingOrderCardScroll/useListingOrderCardScroll';

export default function ListingOrderCard({
  order,
  onChange,
  onOrderUpdate,
  isOrderSelected,
  enableScroll,
}: IListingOrderCard) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));
  const showDesktopStatusBar = !isMobile;
  const { orderCardRef, ...refs } = useListingOrderCardScroll({
    enableScroll,
  });
  const activeStep = LISTING_ORDER_STATUS.indexOf(order.status);

  if (isMobile) {
    if (isOrderSelected) {
      return (
        <OrderMobileWrapper ref={orderCardRef}>
          <ListingOrderOverview
            order={order}
            isMobile={isMobile}
            activeStep={activeStep}
            isOrderSelected
          />
          <SideWrapper>
            <ListingOrderCardDetails
              order={order}
              refs={refs}
              onOrderUpdate={onOrderUpdate}
            />
          </SideWrapper>
        </OrderMobileWrapper>
      );
    } else {
      return (
        <OrderWrapper ref={orderCardRef} key={order.uuid} onClick={onChange}>
          <ListingOrderOverview
            order={order}
            isMobile={isMobile}
            activeStep={activeStep}
          />
        </OrderWrapper>
      );
    }
  }

  return (
    <OrderWrapper key={order.uuid} ref={orderCardRef}>
      <Accordion
        expanded={isOrderSelected}
        id={`order-card.accordion.${order.publicId}.${
          isOrderSelected ? 'close' : 'open'
        }`}
        data-testid={`test-order-card.accordion.${order.publicId}`}
        onChange={onChange}
        square
      >
        <AccordionSummary showActionButton={true}>
          <ListingOrderOverview order={order} activeStep={activeStep} />
          {showDesktopStatusBar && (
            <AccordionSummaryFullWidthRow>
              <OrderStatusBar
                activeStep={activeStep}
                stages={LISTING_ORDER_CARD_STAGES}
              />
            </AccordionSummaryFullWidthRow>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <ListingOrderCardDetails
            order={order}
            refs={refs}
            onOrderUpdate={onOrderUpdate}
          />
        </AccordionDetails>
      </Accordion>
    </OrderWrapper>
  );
}
