import { Typography } from '@mui/material';
import { SimplifiedMobileStatusBox } from './SimplifiedMobileStatusBar.css';
import SimplifiedMobileStatusBarProps from './SimplifiedMobileStatusBar.types';
import ProgressBar from '../../ProgressBar/ProgressBar';
import { useIntl } from 'react-intl';

function SimplifiedMobileStatusBar({
  progress,
  stageLabel,
  stageNumber,
}: SimplifiedMobileStatusBarProps) {
  const intl = useIntl();

  return (
    <SimplifiedMobileStatusBox>
      <Typography variant="h6">
        {intl.formatMessage(
          {
            id: 'customer-platform.shared.components.simplified-status-bar.label',
          },
          {
            CURRENT_STEP: stageNumber.toString(),
            STEP_LABEL: stageLabel,
          },
        )}
      </Typography>
      <ProgressBar value={progress} />
    </SimplifiedMobileStatusBox>
  );
}

export default SimplifiedMobileStatusBar;
