import { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  IUserLoginKeys,
  IUserLoginRequest,
  IUserRoleType,
  useLoginMutation,
} from '@frontend/api';
import { Translate } from '@frontend/translation';
import { Button, Modal, TextField } from '@frontend/ui-elements';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography, useTheme } from '@mui/material';
import {
  IconButton,
  IconWrapper,
  ModalBox,
  ModalHeadingBox,
  ModalSuccessBox,
  ModalSuccessHeadingBox,
} from './LoginModal.css';
import { ILoginModal } from './LoginModal.types';
import { RiCloseLine } from '@remixicon/react';
import FormType from '../../Forms/utils/FormType.types';
import { RiMailSendLine } from '@remixicon/react';
import { useFormTracking } from '../../Forms/utils/useFormTracking';

export function LoginModal({
  isLoginLink,
  isOpen,
  onClose,
  role,
  redirectUrl,
  sourceUrl,
}: ILoginModal) {
  const intl = useIntl();
  const theme = useTheme();
  const formData = useRef<IUserLoginRequest>({});
  const { mutate, isSuccess, isPending, error } = useLoginMutation({
    redirectUrl,
    sourceUrl,
  });

  const requiredField = intl.formatMessage({
    id: 'customer-platform.shared.components.forms.errors.required',
  });

  const formId = 'login-form.form';

  const loginFormSchema = yup.object().shape({
    id: yup.string().optional(),
    email: yup
      .string()
      .email(
        intl.formatMessage({
          id: 'customer-platform.shared.components.forms.errors.email.invalid',
        }),
      )
      .required(requiredField),
    role: yup.mixed<IUserRoleType>().optional(),
  });

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors: formErrors },
  } = useForm<FormType<IUserLoginRequest>>({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver<FormType<IUserLoginRequest>>(loginFormSchema),
  });

  useFormTracking({ formId, formErrors, isSuccess });

  useEffect(() => {
    const loginError = error?.response?.data;
    if (loginError !== undefined) {
      Object.keys(loginError).map((field: string) =>
        setError(field as IUserLoginKeys, {
          type: 'manual',
          message: loginError[field as IUserLoginKeys][0] as string,
        }),
      );
    }
  }, [error, setError]);

  const onSubmit: SubmitHandler<FormType<IUserLoginRequest>> = loginData => {
    formData.current = {
      ...loginData,
      role: role,
    };
    mutate(formData.current);
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalBox>
        <IconButton
          id="login-modal.icon-button.close-modal"
          aria-label={intl.formatMessage({
            id: 'customer-platform.shared.email-verification.modal-box.close',
          })}
          onClick={onClose}
        >
          <RiCloseLine size={24} color={theme.palette.neutral[500]} />
        </IconButton>

        {isSuccess && (
          <ModalSuccessBox>
            <IconWrapper>
              <RiMailSendLine size={77.28} color={theme.palette.neutral[500]} />
              <RiMailSendLine
                size={77.28}
                color={theme.palette.secondary[500]}
              />
            </IconWrapper>

            <ModalSuccessHeadingBox>
              <Typography variant="h2">
                <Translate id="customer-platform.shared.login.login-form.email-sent.heading" />
              </Typography>
              <Typography variant="p3">
                <Translate id="customer-platform.shared.login.login-form.email-sent.subheading" />
              </Typography>
            </ModalSuccessHeadingBox>
          </ModalSuccessBox>
        )}

        {!isSuccess && (
          <>
            <ModalHeadingBox>
              <Typography variant="h4" marginTop="8px">
                <Translate
                  id={
                    isLoginLink
                      ? 'customer-platform.shared.login.login-form.login-link.header.heading'
                      : 'customer-platform.shared.login.login-form.header.heading'
                  }
                />
              </Typography>
              <Typography variant="p3">
                <Translate id="customer-platform.shared.login.login-form.header.subheading" />
              </Typography>
            </ModalHeadingBox>

            <form
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              id={formId}
              name={formId}
            >
              <TextField
                id="email"
                type="email"
                aria-label={intl.formatMessage({
                  id: 'customer-platform.shared.login.login-form.textfield.email',
                })}
                label={
                  <Translate id="customer-platform.shared.login.login-form.textfield.email" />
                }
                {...register('email')}
                error={!!formErrors?.email}
                helperText={formErrors?.email?.message}
                required
                formId={formId}
              />

              <Button
                buttonType="primary"
                id="login-modal.button.submit"
                size="large"
                type="submit"
                isSubmitting={isPending}
              >
                <Translate id="customer-platform.shared.login.login-form.button.login" />
              </Button>
            </form>
          </>
        )}
      </ModalBox>
    </Modal>
  );
}

export default LoginModal;
