import { ICountryCodeFlag } from './CountryCodeFlag.types';

import parsePhoneNumber from 'libphonenumber-js';

const CountryCodeFlag = ({ phoneNumber }: ICountryCodeFlag) => {
  const countryCode = phoneNumber
    ? parsePhoneNumber(phoneNumber)?.country?.toLocaleLowerCase()
    : '';

  return (
    countryCode && (
      <img
        src={`https://flagcdn.com/${countryCode}.svg`}
        height="20"
        width="28"
        alt={countryCode}
      ></img>
    )
  );
};

export default CountryCodeFlag;
