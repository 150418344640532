import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useContext } from 'react';
import { ApiContext } from '../ApiContext/ApiContext';
import axiosClient from '../axiosClient';
import {
  TGetUserProfileResponse,
  IGetUserProfileResponseFailure,
} from './getUserProfile.types';
import { getQueryKey } from './getUserProfile.const';

const submitGetUserProfile = (apiUrl: string) => {
  return axiosClient.get(`${apiUrl}/accounts/user/profile-data/`);
};

const useGetUserProfile = () => {
  const { apiUrl } = useContext(ApiContext);
  return useQuery<
    AxiosResponse<TGetUserProfileResponse>,
    AxiosError<IGetUserProfileResponseFailure>,
    AxiosResponse<TGetUserProfileResponse>
  >({
    queryFn: () => submitGetUserProfile(apiUrl),
    queryKey: getQueryKey(),
  });
};
export default useGetUserProfile;
