import { Translate } from '@frontend/translation';
import { Button, Modal } from '@frontend/ui-elements';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { RiUserAddLine } from '@remixicon/react';
import {
  ButtonWrapper,
  IconWrapper,
  ModalBox,
  ModalHeadingBox,
  ModalTextBox,
} from './CreateAdditionalRoleModal.css';
import { ICreateAdditionalRoleModal } from './CreateAdditionalRoleModal.types';
import { useAuth } from '@frontend/auth';
import { useUpdateAccountToBuyerAndSeller } from '@frontend/api';
import { useEffect, useState } from 'react';

export function CreateAdditionalRoleModal({
  isOpen,
  newRoleName,
  onClose,
  onAccountUpdated,
}: ICreateAdditionalRoleModal) {
  const theme = useTheme();
  const { currentUser } = useAuth();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const isTabletOrLess = useMediaQuery(theme.breakpoints.down('tablet'));

  const { mutate: updateAccount, isSuccess: updateAccountSuccess } =
    useUpdateAccountToBuyerAndSeller();

  useEffect(() => {
    if (updateAccountSuccess) {
      onClose();
      onAccountUpdated();
    }
  }, [onAccountUpdated, onClose, updateAccountSuccess]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalBox>
        <ModalHeadingBox>
          <IconWrapper>
            <RiUserAddLine
              size={isTabletOrLess ? 58 : 96}
              color={theme.palette.neutral[500]}
            />
            <RiUserAddLine
              size={isTabletOrLess ? 58 : 96}
              color={theme.palette.secondary[500]}
            />
          </IconWrapper>

          <ModalTextBox>
            <Typography component="h4" variant={isTabletOrLess ? 'h4' : 'h2'}>
              <Translate
                id="customer-platform.shared.create-additional-role.heading"
                values={{ ROLE_NAME: newRoleName }}
              />
            </Typography>
            <Typography variant="p3">
              <Translate
                id="customer-platform.shared.create-additional-role.subheading"
                values={{
                  ROLE_NAME: newRoleName,
                  USER_EMAIL: (
                    <Typography component="span" variant="p1">
                      {currentUser?.email}
                    </Typography>
                  ),
                }}
              />
            </Typography>
          </ModalTextBox>
        </ModalHeadingBox>

        <ButtonWrapper>
          <Button
            buttonType="secondary"
            id="create-buyer-seller-account-modal.button.secondary"
            size="large"
            onClick={onClose}
          >
            <Translate id="customer-platform.shared.create-additional-role.secondary" />
          </Button>

          <Button
            buttonType="primary"
            id="create-buyer-seller-account-modal.button.primary"
            size="large"
            disabled={isProcessing}
            isSubmitting={isProcessing}
            onClick={() => {
              setIsProcessing(true);
              updateAccount();
            }}
          >
            <Translate
              id="customer-platform.shared.create-additional-role.cta"
              values={{
                ROLE_NAME: newRoleName,
              }}
            />
          </Button>
        </ButtonWrapper>
      </ModalBox>
    </Modal>
  );
}

export default CreateAdditionalRoleModal;
