import IconButton from '../../IconButton/IconButton';
import { Typography, useTheme } from '@mui/material';
import {
  BodyMobileStatusWrapper,
  HeadingMobileStatusWrapper,
  MobileStatusBox,
} from './MobileStatusBar.css';
import MobileStatusBarProps from './MobileStatusBar.types';
import { RiArrowDownSLine } from '@remixicon/react';
import { RiArrowUpSLine } from '@remixicon/react';
import ProgressBar from '../../ProgressBar/ProgressBar';
import StepsBar from '../../StepsBar/StepsBar';
import { useIntl } from 'react-intl';

function MobileStatusBar({
  activeStep,
  onClick,
  open,
  stages,
}: MobileStatusBarProps) {
  const theme = useTheme();
  const intl = useIntl();

  return (
    <>
      <MobileStatusBox>
        <Typography
          component="p"
          variant="caption4"
          color={theme.palette.common.white}
        >
          {intl.formatMessage(
            { id: 'customer-platform.shared.components.status-bar.label' },
            {
              CURRENT_STEP: (activeStep + 1)?.toString(),
              TOTAL_STEPS: stages?.length?.toString(),
            },
          )}
        </Typography>
        <HeadingMobileStatusWrapper>
          <Typography
            component="h6"
            variant="p1"
            color={theme.palette.common.white}
          >
            {stages.find(stage => stage?.order === activeStep)?.label}
          </Typography>
          <IconButton
            id={`mobile-status-bar.icon-button.${open ? 'close' : 'open'}`}
            onClick={onClick}
          >
            {open ? (
              <RiArrowUpSLine size={24} color={theme.palette.common.white} />
            ) : (
              <RiArrowDownSLine size={24} color={theme.palette.common.white} />
            )}
          </IconButton>
        </HeadingMobileStatusWrapper>
        <ProgressBar value={((activeStep + 1) / stages.length) * 100} />
      </MobileStatusBox>
      {open && (
        <BodyMobileStatusWrapper>
          <StepsBar
            activeStep={activeStep}
            connector={null}
            stages={stages}
            orientation="vertical"
          />
        </BodyMobileStatusWrapper>
      )}
    </>
  );
}

export default MobileStatusBar;
