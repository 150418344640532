import { IUserProfile } from '@frontend/api';
import {
  InfoItem,
  InfoSection,
  InfoWrapper,
  PhoneNumberWrapper,
} from './ProfileCompanyDetails.css';
import { Typography } from '@mui/material';
import { Translate } from '@frontend/translation';
import CountryCodeFlag from '../CountryCodeFlag/CountryCodeFlag';

export default function ProfileCompanyDetails({ user, company }: IUserProfile) {
  return (
    <InfoWrapper>
      <InfoSection>
        <Typography variant="h6">
          <Translate id="customer-platform.shared.user-profile.profile.title" />
        </Typography>
        <InfoItem>
          <Typography variant="p2">
            <Translate id="customer-platform.shared.user-profile.profile.first-name" />
          </Typography>
          <Typography variant="p3">{user.firstName}</Typography>
        </InfoItem>
        <InfoItem>
          <Typography variant="p2">
            <Translate id="customer-platform.shared.user-profile.profile.last-name" />
          </Typography>
          <Typography variant="p3">{user.lastName}</Typography>
        </InfoItem>
        <InfoItem>
          <Typography variant="p2">
            <Translate id="customer-platform.shared.user-profile.profile.email" />
          </Typography>
          <Typography variant="p3">{user.email}</Typography>
        </InfoItem>
        <InfoItem>
          <Typography variant="p2">
            <Translate id="customer-platform.shared.user-profile.profile.phone-number" />
          </Typography>
          <PhoneNumberWrapper>
            <CountryCodeFlag phoneNumber={user.phoneNumber} />
            <Typography variant="p3">{user.phoneNumber}</Typography>
          </PhoneNumberWrapper>
        </InfoItem>
        <InfoItem>
          <Typography variant="p2">
            <Translate id="customer-platform.shared.user-profile.profile.whatsapp-number" />
          </Typography>
          <PhoneNumberWrapper>
            <CountryCodeFlag phoneNumber={user.whatsappPhoneNumber} />
            <Typography variant="p3">{user.whatsappPhoneNumber}</Typography>
          </PhoneNumberWrapper>
        </InfoItem>
        <InfoItem>
          <Typography variant="p2">
            <Translate id="customer-platform.shared.user-profile.profile.whatsapp-consent" />
          </Typography>
          <Typography variant="p3">
            {user.whatsappConsent ? (
              <Translate id="customer-platform.shared.user-profile.profile.whatsapp-consent.yes" />
            ) : (
              <Translate id="customer-platform.shared.user-profile.profile.whatsapp-consent.no" />
            )}
          </Typography>
        </InfoItem>
      </InfoSection>
      <InfoSection>
        <Typography variant="h6">
          <Translate id="customer-platform.shared.user-profile.company.title" />
        </Typography>
        <InfoItem>
          <Typography variant="p2">
            <Translate id="customer-platform.shared.user-profile.company.name" />
          </Typography>
          <Typography variant="p3">{company.companyName}</Typography>
        </InfoItem>
        <InfoItem>
          <Typography variant="p2">
            <Translate id="customer-platform.shared.user-profile.company.address" />
          </Typography>
          <Typography variant="p3">
            {company.address ? (
              company.address
            ) : (
              <Translate id="customer-platform.shared.user-profile.profile.no-data" />
            )}
          </Typography>
        </InfoItem>
        <InfoItem>
          <Typography variant="p2">
            <Translate id="customer-platform.shared.user-profile.company.postal-code" />
          </Typography>
          <Typography variant="p3">
            {company.postalCode ? (
              company.postalCode
            ) : (
              <Translate id="customer-platform.shared.user-profile.profile.no-data" />
            )}
          </Typography>
        </InfoItem>
        <InfoItem>
          <Typography variant="p2">
            <Translate id="customer-platform.shared.user-profile.company.vat-number" />
          </Typography>
          <Typography variant="p3">
            {company.vatNumber ? (
              company.vatNumber
            ) : (
              <Translate id="customer-platform.shared.user-profile.profile.no-data" />
            )}
          </Typography>
        </InfoItem>
      </InfoSection>
    </InfoWrapper>
  );
}
