import { Link, ToastAlert } from '@frontend/ui-elements';
import { COMPANY_VERIFICATION_STATUS } from '@frontend/api';
import { useAuth } from '@frontend/auth';
import { Translate } from '@frontend/translation';
import { useTheme } from '@mui/material';
import { head } from 'lodash';
import { TVerificationStatusMap } from './CompanyStatusBanner.types';

export default function CompanyStatusBanner() {
  const { currentUser } = useAuth();
  const theme = useTheme();

  if (!currentUser) {
    return null;
  }

  const { verificationStatus, detectedPortalId, pipedriveId } =
    currentUser.company;

  if (verificationStatus === COMPANY_VERIFICATION_STATUS.APPROVED) {
    return null;
  }

  const incompleteProfile = {
    title: (
      <Translate id="customer-platform.request-list.company-status.profile-incomplete.title" />
    ),
    subtitle: (
      <Translate
        id="customer-platform.request-list.company-status.additional-info-required.subtitle"
        values={{
          a: (chunks: string[]) => (
            <Link
              id="company-status-banner.additional-info-required"
              color={theme.palette.neutral[500]}
              target="_blank"
              rel="noopener noreferrer"
              href={`https://profile.detected.app/portal/metycle/${detectedPortalId}`}
            >
              {head(chunks)}
            </Link>
          ),
        }}
      />
    ),
  };

  const declinedProfile = {
    title: (
      <Translate id="customer-platform.request-list.company-status.declined.title" />
    ),
    subtitle: (
      <Translate
        id="customer-platform.request-list.company-status.declined.subtitle"
        values={{
          a: (chunks: string[]) => (
            <Link
              id="company-status-banner.declined"
              color={theme.palette.neutral[500]}
              href="mailto:support@metycle.com"
            >
              {head(chunks)}
            </Link>
          ),
        }}
      />
    ),
  };

  const verifyingCompany = {
    title: (
      <Translate id="customer-platform.request-list.company-status.processed.title" />
    ),
    subtitle: (
      <Translate id="customer-platform.request-list.company-status.processed.subtitle" />
    ),
  };

  const messageMap: TVerificationStatusMap = {
    [COMPANY_VERIFICATION_STATUS.ADDITIONAL_INFO_REQUIRED]: incompleteProfile,
    [COMPANY_VERIFICATION_STATUS.ARCHIVE]: declinedProfile,
    [COMPANY_VERIFICATION_STATUS.DECLINED]: declinedProfile,
    [COMPANY_VERIFICATION_STATUS.NEEDS_REVIEW]: verifyingCompany,
    [COMPANY_VERIFICATION_STATUS.PROCESSED]: verifyingCompany,
    [COMPANY_VERIFICATION_STATUS.PROFILE_INCOMPLETE]: incompleteProfile,
  };

  const currentTitle = verificationStatus ? (
    messageMap[verificationStatus]?.title
  ) : (
    <Translate id="customer-platform.request-list.company-status.no-profile.title" />
  );

  const currentSubtitle = verificationStatus ? (
    messageMap[verificationStatus]?.subtitle
  ) : (
    <Translate
      id="customer-platform.request-list.company-status.no-profile.subtitle"
      values={{
        a: (chunks: string[]) => (
          <Link
            id="company-status-banner.no-profile"
            color={theme.palette.neutral[500]}
            target="_blank"
            rel="noopener noreferrer"
            href={`https://profile.detected.app/portal/find-company/metycle?reference=${pipedriveId}`}
          >
            {head(chunks)}
          </Link>
        ),
      }}
    />
  );

  return (
    <ToastAlert
      title={currentTitle}
      message={currentSubtitle}
      severity="warning"
    />
  );
}
