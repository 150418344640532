import { createClient } from 'contentful';

// Somehow the null safety in the env variable and the non-empty string as
// fallback are needed, as the createClient remove empty strings from its object,
// so it won't build with an empty string '' fallback

const client = createClient({
  space: (import.meta.env?.VITE_CONTENTFUL_SPACE_ID as string) || 'space',
  accessToken:
    (import.meta.env?.VITE_CONTENTFUL_ACCESS_TOKEN as string) || 'accessToken',
});

export default client;
