import { ApiContextProvider } from '@frontend/api';
import { AuthContextProvider, CheckExistingSession } from '@frontend/auth';
import { ThemeProvider } from '@frontend/theme';
import { TranslationProvider } from '@frontend/translation';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import Router from './router';
import { LDProvider as LaunchDarklyProvider } from 'launchdarkly-react-client-sdk';

export function App() {
  return (
    <LaunchDarklyProvider
      clientSideID={import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID}
      options={{ streaming: false }}
      reactOptions={{ useCamelCaseFlagKeys: false }}
    >
      <TranslationProvider>
        <ThemeProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ApiContextProvider apiUrl={import.meta.env.VITE_METYCLE_API_URL}>
              <AuthContextProvider>
                <HelmetProvider>
                  <BrowserRouter>
                    <CheckExistingSession>
                      <Router />
                    </CheckExistingSession>
                  </BrowserRouter>
                </HelmetProvider>
              </AuthContextProvider>
            </ApiContextProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </TranslationProvider>
    </LaunchDarklyProvider>
  );
}

export default App;
