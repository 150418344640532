import { useMatch, useNavigate, useParams } from 'react-router-dom';
import { PLATFORM_ROUTES } from '@frontend/routing';
import { Translate } from '@frontend/translation';
import { Button } from '@frontend/ui-elements';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  ButtonWrapper,
  CardCounter,
  CardCounterWrapper,
  CounterOrButtonWrapper,
  DashboardHeaderWrapper,
  Separator,
} from './DashboardHeader.css';
import { DashboardHeaderProps } from './DashboardHeader.types';
import { RiArrowLeftLine } from '@remixicon/react';
import { ROUTE_NAMES } from '@frontend/utils';
import NotificationBanners from '../NotificationBanners/NotificationBanners';

export default function DashboardHeader({ cardsCount }: DashboardHeaderProps) {
  const navigate = useNavigate();
  const { orderId } = useParams();
  const theme = useTheme();
  const buyerDashboardMatch = useMatch(PLATFORM_ROUTES.buyerDashboard);
  const buyerDashboardRoutesMatch = useMatch(
    `${PLATFORM_ROUTES.buyerDashboard}/*`,
  );
  const buyerDashboardRouteMatched =
    buyerDashboardMatch || buyerDashboardRoutesMatch;
  const currentVariantType = buyerDashboardRouteMatched
    ? ROUTE_NAMES.REQUEST
    : ROUTE_NAMES.LISTING;
  const isTablet = useMediaQuery(theme.breakpoints.down('desktop'));
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));
  const showCounter =
    cardsCount !== undefined && (orderId === undefined || !isTablet);
  const showHeader = !(isMobile && orderId !== undefined);

  const handleClick = () => {
    if (currentVariantType === ROUTE_NAMES.REQUEST)
      navigate(PLATFORM_ROUTES.buyerDashboard);
    else {
      navigate(PLATFORM_ROUTES.sellerDashboard);
    }
  };

  return (
    showHeader && (
      <DashboardHeaderWrapper isCardsView={showCounter}>
        <Typography variant="h2">
          <Translate id="customer-platform.request-list.heading" />
        </Typography>
        <NotificationBanners />
        <CounterOrButtonWrapper>
          {showCounter ? (
            <CardCounterWrapper>
              <CardCounter>
                <Typography variant="p2">
                  <Translate
                    id={`customer-platform.${currentVariantType}-list.number-of-${currentVariantType}s`}
                  />
                </Typography>
                <Typography variant="p4">{cardsCount}</Typography>
              </CardCounter>
              <Separator />
            </CardCounterWrapper>
          ) : (
            <ButtonWrapper>
              <Button
                buttonType="linked"
                id={`${currentVariantType}-details-header.button.go-back`}
                linkedButtonColorValue="neutral"
                startIcon={<RiArrowLeftLine size={24} />}
                size="large"
                onClick={handleClick}
              >
                <Translate
                  id={`customer-platform.${currentVariantType}-details.header.previous-page-cta`}
                />
              </Button>
            </ButtonWrapper>
          )}
        </CounterOrButtonWrapper>
      </DashboardHeaderWrapper>
    )
  );
}
