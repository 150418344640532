import { formatDateWithDots, formatNumberToUS } from '@frontend/utils';
import { Translate } from '@frontend/translation';
import { AccordionSummary, Button, EmptySpace } from '@frontend/ui-elements';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  ButtonWrapper,
  ItemTitleValueWrapper,
  RequestAccordion,
  RequestAccordionDetails,
  RequestCardWrapper,
  RequestDetails,
  RequestDetailsItem,
  RequestNoAndDateWrapper,
  RequestOverview,
} from './RequestCard.css';
import { IRequestCard } from './RequestCard.types';
import {
  RiArrowUpDownLine,
  RiFoldersLine,
  RiMapPinLine,
  RiMoneyDollarCircleLine,
  RiRecycleLine,
} from '@remixicon/react';
import NullableCardDetailsItem from '@customer-platform-shared/components/Cards/NullableCardDetailsItem/NullableCardDetailsItem';
import StatusPill from '@customer-platform-shared/components/Cards/StatusPill/StatusPill';
import { useCardScroll } from '@customer-platform-shared/components/useCardScroll/useCardScroll';

export default function RequestCard({
  request: {
    publicId,
    requestNumberId,
    status,
    quantity,
    metalType,
    shippingPort,
    salesPrice,
    incoterms,
    createdDate,
  },
  expanded,
  enableScroll,
  onClick,
}: IRequestCard) {
  const theme = useTheme();
  const isDesktopOrBigger = useMediaQuery(theme.breakpoints.up('desktop'));
  const isTabletOrSmaller = !isDesktopOrBigger;

  const { cardRef } = useCardScroll({
    enableScroll,
  });

  return (
    <RequestCardWrapper>
      <RequestAccordion
        expanded={expanded}
        id={`request-card.accordion.${publicId}`}
        onClick={isDesktopOrBigger && !expanded ? onClick : undefined}
        square
        ref={cardRef}
      >
        <AccordionSummary showActionButton={isDesktopOrBigger}>
          <RequestOverview>
            <RequestNoAndDateWrapper>
              <Typography variant="h6">
                <Translate
                  id="customer-platform.request-list.request-card.order-number"
                  values={{
                    REQUEST_NUMBER_ID: requestNumberId,
                  }}
                />
              </Typography>
              <Typography variant="caption4" component="span">
                <Translate
                  id="customer-platform.request-list.request-card.created-date"
                  values={{
                    CREATION_DATE: formatDateWithDots(createdDate),
                  }}
                />
              </Typography>
            </RequestNoAndDateWrapper>
            <StatusPill status={status} />
          </RequestOverview>
        </AccordionSummary>

        <RequestAccordionDetails>
          <RequestDetails>
            <RequestDetailsItem data-testid="weight-info">
              <RiArrowUpDownLine size={16} />
              <ItemTitleValueWrapper>
                <Typography variant="caption1" component="span">
                  <Translate id="customer-platform.request-list.request-card.weight" />
                </Typography>
                <NullableCardDetailsItem data={quantity}>
                  <Typography variant="caption2" component="span">
                    {formatNumberToUS(quantity as number)}
                    <EmptySpace />
                    <Translate id="customer-platform.request-list.request-card.weight-unit" />
                  </Typography>
                </NullableCardDetailsItem>
              </ItemTitleValueWrapper>
            </RequestDetailsItem>

            <RequestDetailsItem data-testid="metal-type-info">
              <RiRecycleLine size={16} />
              <ItemTitleValueWrapper>
                <Typography variant="caption1" component="span">
                  <Translate id="customer-platform.request-list.request-card.metal-type" />
                </Typography>
                <Typography variant="caption2" component="span">
                  <Translate id={`customer-platform.api.${metalType}`} />
                </Typography>
              </ItemTitleValueWrapper>
            </RequestDetailsItem>

            <RequestDetailsItem data-testid="incoterms-info">
              <RiFoldersLine size={16} />
              <ItemTitleValueWrapper>
                <Typography variant="caption1" component="span">
                  <Translate id="customer-platform.request-list.request-card.incoterms" />
                </Typography>
                <NullableCardDetailsItem data={incoterms}>
                  <Typography variant="caption2" component="span">
                    <Translate id={`customer-platform.api.${incoterms}`} />
                  </Typography>
                </NullableCardDetailsItem>
              </ItemTitleValueWrapper>
            </RequestDetailsItem>

            <RequestDetailsItem data-testid="destination-info">
              <RiMapPinLine size={16} />
              <ItemTitleValueWrapper>
                <Typography variant="caption1" component="span">
                  <Translate id="customer-platform.request-list.request-card.destination" />
                </Typography>
                <NullableCardDetailsItem data={shippingPort}>
                  <Typography variant="caption2" component="span">
                    {shippingPort}
                  </Typography>
                </NullableCardDetailsItem>
              </ItemTitleValueWrapper>
            </RequestDetailsItem>

            <RequestDetailsItem data-testid="sales-price-info">
              <RiMoneyDollarCircleLine size={16} />
              <ItemTitleValueWrapper>
                <Typography variant="caption1" component="span">
                  <Translate id="customer-platform.request-list.request-card.sales-price" />
                </Typography>
                <Typography variant="caption2" component="span">
                  {salesPrice && status !== 'request.status.pending' ? (
                    <>
                      {formatNumberToUS(salesPrice as number)}
                      <EmptySpace />
                      <Translate id="customer-platform.request-list.request-card.sales-price-unit" />
                    </>
                  ) : (
                    <Translate id="customer-platform.request-list.request-card.sales-price-unknown" />
                  )}
                </Typography>
              </ItemTitleValueWrapper>
            </RequestDetailsItem>
          </RequestDetails>
          {isTabletOrSmaller && (
            <ButtonWrapper>
              <Button
                buttonType="primary"
                id={`request-card.button.open-request-${publicId}`}
                size="small"
                onClick={onClick}
              >
                <Translate id="customer-platform.request-list.request-card.cta" />
              </Button>
            </ButtonWrapper>
          )}
        </RequestAccordionDetails>
      </RequestAccordion>
    </RequestCardWrapper>
  );
}
