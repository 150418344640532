import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

export const UserProfileWrapper = styled(MUIBox)({});

export const EditButtonWrapper = styled(MUIBox)({
  marginTop: '24px',
});

export const LoaderWrapper = styled(MUIBox)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '50vh',
}));
