import {
  Accordion,
  AccordionDetails,
  AccordionSummaryFullWidthRow,
  SideWrapper,
} from '@frontend/ui-elements';
import { REQUEST_ORDER_STATUS } from '@frontend/api';
import { useMediaQuery, useTheme } from '@mui/material';
import {
  AccordionSummary,
  OrderMobileWrapper,
  OrderWrapper,
} from './RequestOrderCard.css';
import { IRequestOrderCard } from './RequestOrderCard.types';
import RequestOrderCardDetails from './RequestOrderCardDetails/RequestOrderCardDetails';
import RequestOrderOverview from './RequestOrderOverview/RequestOrderOverview';
import OrderStatusBar from '@customer-platform-shared/components/OrderStatusBar/OrderStatusBar';
import { REQUEST_ORDER_CARD_STAGES } from '@customer-platform-shared/components/OrderStatusBar/OrderStatusBar.const';
import { useRequestOrderCardScroll } from './useRequestOrderCardScroll/useRequestOrderCardScroll';

export default function RequestOrderCard({
  order,
  onChange,
  isOrderSelected,
  enableScroll,
}: IRequestOrderCard) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));
  const showDesktopStatusBar = !isMobile;
  const { orderCardRef, ...refs } = useRequestOrderCardScroll({
    enableScroll: !!enableScroll,
  });
  const activeStep = REQUEST_ORDER_STATUS.indexOf(order.status);
  if (isMobile) {
    if (isOrderSelected) {
      return (
        <OrderMobileWrapper ref={orderCardRef}>
          <RequestOrderOverview
            order={order}
            isMobile={isMobile}
            activeStep={activeStep}
            isOrderSelected
          />
          <SideWrapper>
            <RequestOrderCardDetails order={order} refs={refs} />
          </SideWrapper>
        </OrderMobileWrapper>
      );
    } else {
      return (
        <OrderWrapper ref={orderCardRef} key={order.uuid} onClick={onChange}>
          <RequestOrderOverview
            order={order}
            isMobile={isMobile}
            activeStep={activeStep}
          />
        </OrderWrapper>
      );
    }
  }

  return (
    <OrderWrapper key={order.uuid} ref={orderCardRef}>
      <Accordion
        expanded={isOrderSelected}
        id={`order-card.accordion.${order.publicId}.${
          isOrderSelected ? 'close' : 'open'
        }`}
        data-testid={`test-order-card.accordion.${order.publicId}`}
        onChange={onChange}
        square
      >
        <AccordionSummary showActionButton={true}>
          <RequestOrderOverview order={order} activeStep={activeStep} />
          {showDesktopStatusBar && (
            <AccordionSummaryFullWidthRow>
              <OrderStatusBar
                activeStep={activeStep}
                stages={REQUEST_ORDER_CARD_STAGES}
              />
            </AccordionSummaryFullWidthRow>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <RequestOrderCardDetails order={order} refs={refs} />
        </AccordionDetails>
      </Accordion>
    </OrderWrapper>
  );
}
