import { useGetUserProfile } from '@frontend/api';
import { LoaderWrapper, UserProfileWrapper } from './UserProfile.css';
import { CircularProgress } from '@mui/material';
import ProfileCompanyDetails from './ProfileCompanyDetails/ProfileCompanyDetails';
import { ToastAlert } from '@frontend/ui-elements';
import { Translate } from '@frontend/translation';
import { RiAlertLine } from '@remixicon/react';

export default function UserProfile() {
  const {
    isLoading,
    isSuccess,
    isError,
    data: userProfileResponse,
  } = useGetUserProfile();

  return (
    <>
      {isLoading && (
        <LoaderWrapper>
          <CircularProgress size={160} />
        </LoaderWrapper>
      )}
      {isSuccess && userProfileResponse.data && (
        <UserProfileWrapper>
          <ProfileCompanyDetails {...userProfileResponse.data} />
        </UserProfileWrapper>
      )}
      {isError && (
        <UserProfileWrapper>
          <ToastAlert
            severity="error"
            title={
              <Translate id="customer-platform.shared.user-profile.get-profile.error.title" />
            }
            message={
              <Translate id="customer-platform.shared.user-profile.get-profile.error.message" />
            }
            icon={<RiAlertLine size={24} />}
          ></ToastAlert>
        </UserProfileWrapper>
      )}
    </>
  );
}
