import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

export const InfoWrapper = styled(MUIBox)({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
});

export const InfoSection = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  paddingBottom: '16px',
  '&:first-of-type': {
    borderBottom: `1px solid ${theme.palette.neutral[50]}`,
    paddingBottom: '24px',
  },
}));

export const InfoItem = styled(MUIBox)({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
});

export const PhoneNumberWrapper = styled(MUIBox)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px',
});
