import { trackModalViewEvent } from '@frontend/tracking';
import { useCallback, useState } from 'react';
import { MODAL_VARIANT, ModalVariant } from './useModal.types';

const useModal = () => {
  const [currentOpenModal, setCurrentOpenModal] = useState<
    ModalVariant | undefined
  >(undefined);

  const handleTrackModalViewEvent = (modalBox: ModalVariant) => {
    trackModalViewEvent({
      elementId: `${modalBox}-modal.open-modal`,
    });
  };

  const openInfoModal = useCallback(() => {
    handleTrackModalViewEvent(MODAL_VARIANT.INFO);
    setCurrentOpenModal(MODAL_VARIANT.INFO);
  }, []);

  const openLoginModal = useCallback(() => {
    handleTrackModalViewEvent(MODAL_VARIANT.LOGIN);
    setCurrentOpenModal(MODAL_VARIANT.LOGIN);
  }, []);

  const openLinkExpiredModal = useCallback(() => {
    handleTrackModalViewEvent(MODAL_VARIANT.LOGIN_LINK_EXPIRED);
    setCurrentOpenModal(MODAL_VARIANT.LOGIN_LINK_EXPIRED);
  }, []);

  const openLinkLoginModal = useCallback(() => {
    handleTrackModalViewEvent(MODAL_VARIANT.LOGIN_LINK);
    setCurrentOpenModal(MODAL_VARIANT.LOGIN_LINK);
  }, []);

  const openSuccessModal = useCallback(() => {
    handleTrackModalViewEvent(MODAL_VARIANT.SUCCESS);
    setCurrentOpenModal(MODAL_VARIANT.SUCCESS);
  }, []);

  const openEmailVerificationModal = useCallback(() => {
    handleTrackModalViewEvent(MODAL_VARIANT.VERIFICATION_EMAIL);
    setCurrentOpenModal(MODAL_VARIANT.VERIFICATION_EMAIL);
  }, []);

  const openEmailVerificationLinkExpiredModal = useCallback(() => {
    handleTrackModalViewEvent(MODAL_VARIANT.VERIFICATION_EMAIL_EXPIRED);
    setCurrentOpenModal(MODAL_VARIANT.VERIFICATION_EMAIL_EXPIRED);
  }, []);

  const openCreateAdditionalRoleModal = useCallback(() => {
    handleTrackModalViewEvent(MODAL_VARIANT.SWITCH_ROLE);
    setCurrentOpenModal(MODAL_VARIANT.SWITCH_ROLE);
  }, []);

  const closeModal = useCallback(() => {
    setCurrentOpenModal(undefined);
  }, []);

  const isInfoModalOpen = currentOpenModal === MODAL_VARIANT.INFO;
  const isLoginModalOpen = currentOpenModal === MODAL_VARIANT.LOGIN;
  const isLinkLoginModalOpen = currentOpenModal === MODAL_VARIANT.LOGIN_LINK;
  const isSuccessModalOpen = currentOpenModal === MODAL_VARIANT.SUCCESS;
  const isLinkExpiredModalOpen =
    currentOpenModal === MODAL_VARIANT.LOGIN_LINK_EXPIRED;
  const isEmailVerificationModalOpen =
    currentOpenModal === MODAL_VARIANT.VERIFICATION_EMAIL;
  const isEmailVerificationLinkExpiredModalOpen =
    currentOpenModal === MODAL_VARIANT.VERIFICATION_EMAIL_EXPIRED;
  const isCreateAdditionalRoleModalOpen =
    currentOpenModal === MODAL_VARIANT.SWITCH_ROLE;

  return {
    openInfoModal,
    openLoginModal,
    openLinkExpiredModal,
    openLinkLoginModal,
    openSuccessModal,
    openEmailVerificationModal,
    openEmailVerificationLinkExpiredModal,
    openCreateAdditionalRoleModal,
    closeModal,
    isInfoModalOpen,
    isLoginModalOpen,
    isSuccessModalOpen,
    isLinkExpiredModalOpen,
    isLinkLoginModalOpen,
    isEmailVerificationModalOpen,
    isEmailVerificationLinkExpiredModalOpen,
    isCreateAdditionalRoleModalOpen,
  };
};

export default useModal;
