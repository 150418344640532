import { Box as MUIBox } from '@mui/material';
import { styled } from '@frontend/theme';

export const BannerWrapper = styled(MUIBox)({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  '& > :first-of-type': {
    marginTop: '40px',
  },
});
