import { MouseEvent, SyntheticEvent, useCallback, useState } from 'react';
import { THeaderElement } from './useHeader.types';

const useHeader = () => {
  const [currentOpenElement, setCurrentOpenElement] = useState<
    THeaderElement | HTMLElement | undefined
  >();
  const [isToastOpen, setIsToastOpen] = useState(false);

  const openBurgerDrawer = useCallback(() => {
    setCurrentOpenElement('burger');
  }, []);

  const openFormDrawer = useCallback(() => {
    setCurrentOpenElement('form');
  }, []);

  const openDropdownMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    setCurrentOpenElement(event.currentTarget);
  }, []);

  const openLogoutModal = useCallback(() => {
    setCurrentOpenElement('logout');
  }, []);

  const closeElement = () => setCurrentOpenElement(undefined);
  const openToast = () => setIsToastOpen(true);
  const closeToast = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsToastOpen(false);
  };

  const openProfileDrawer = useCallback(() => {
    setCurrentOpenElement('profile');
  }, []);

  const isBurgerDrawerOpen = currentOpenElement === 'burger';
  const isFormDrawerOpen = currentOpenElement === 'form';
  const isDropdownMenuOpen = currentOpenElement instanceof HTMLElement;
  const isLogoutModalOpen = currentOpenElement === 'logout';
  const isProfileDrawerOpen = currentOpenElement === 'profile';

  return {
    currentOpenElement,
    openBurgerDrawer,
    openFormDrawer,
    openDropdownMenu,
    openLogoutModal,
    openToast,
    openProfileDrawer,
    closeElement,
    closeToast,
    isBurgerDrawerOpen,
    isFormDrawerOpen,
    isDropdownMenuOpen,
    isLogoutModalOpen,
    isToastOpen,
    isProfileDrawerOpen,
  };
};

export default useHeader;
