export const MODAL_VARIANT = {
  INFO: 'info',
  LOGIN: 'login',
  LOGIN_LINK: 'linkLogin',
  LOGIN_LINK_EXPIRED: 'linkExpired',
  SUCCESS: 'success',
  SWITCH_ROLE: 'createAdditionalRole',
  VERIFICATION_EMAIL: 'emailVerification',
  VERIFICATION_EMAIL_EXPIRED: 'emailVerificationLinkExpired',
} as const;

export type ModalVariant = (typeof MODAL_VARIANT)[keyof typeof MODAL_VARIANT];
