import { Typography } from '@mui/material';
import { formatUnixTimestampToDateWithSpaces } from '@frontend/utils';
import { Translate } from '@frontend/translation';
import { OrderValue, OrderValueLabel } from '../RequestOrderCardDetails.css';
import { RiTimeLine } from '@remixicon/react';
import { TimeLabelAndValueProps } from './TimeLabelAndValue.types';

export default function TimeLabelAndValue({
  actualTime,
  estimatedTime,
  labelActual,
  labelEstimated,
  dataTestId,
}: TimeLabelAndValueProps) {
  return (
    <OrderValue data-testid={dataTestId}>
      <OrderValueLabel>
        <RiTimeLine size={18} />
        <Typography variant="p1" component="span">
          {actualTime ? (
            <Translate id={labelActual} />
          ) : (
            <Translate id={labelEstimated} />
          )}
        </Typography>
      </OrderValueLabel>
      <Typography variant="p3" component="span">
        {actualTime ? (
          formatUnixTimestampToDateWithSpaces(actualTime)
        ) : estimatedTime ? (
          formatUnixTimestampToDateWithSpaces(estimatedTime)
        ) : (
          <Translate id="customer-platform.request-details.order-card.not-available" />
        )}
      </Typography>
    </OrderValue>
  );
}
